import { render, staticRenderFns } from "./ TicketButton.vue?vue&type=template&id=629a85b0&scoped=true&"
import script from "./ TicketButton.vue?vue&type=script&lang=js&"
export * from "./ TicketButton.vue?vue&type=script&lang=js&"
import style0 from "./ TicketButton.vue?vue&type=style&index=0&id=629a85b0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "629a85b0",
  null
  
)

export default component.exports